import { Box } from '@mui/material';
import HeaderUserbox from './user-box/HeaderUserbox';

const Header = () => {
  return (
    <Box
      className="HeaderWrapper headerSection"
      display="flex"
      alignItems="center"
      justifyContent="end"
    >
      <Box display="flex" alignItems="center">
        <HeaderUserbox />
      </Box>
    </Box>
  );
};

export default Header;
