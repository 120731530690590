import React, { useState } from 'react';
import { Box, IconButton, InputAdornment } from '@mui/material';
import { useTranslation } from 'react-i18next';
import CloseIcon from '@mui/icons-material/Close';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { Controller, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import z from 'zod';

import {
  Button,
  CircularProgress,
  SimpleDialog,
  TextField,
  showErrorMessage,
  showSuccessMessage
} from '../../shared/components/index';
import { resetPassword } from './api/authApi';

interface ResetPasswordDialogProps {
  showResetPasswordDialog: boolean;
  handleClose: () => void;
}

const ResetPasswordDialog = ({
  showResetPasswordDialog,
  handleClose
}: ResetPasswordDialogProps) => {
  //Constants
  const { t } = useTranslation('english');
  const symbol = /[ `!@#$%^&*()_+\-=\]{};':"\\|,.<>?~]/;
  const number = /\d/;

  //Zod Schema
  const ResetPasswordFormSchema = z
    .object({
      current_password: z
        .string()
        .min(1, { message: 'Current password is required' }),
      password: z
        .string()
        .min(1, { message: 'Password is required' })
        .min(9, { message: t('invalidPasswordErrMsg') })
        .max(16, { message: t('invalidPasswordErrMsg') }),
      confirm_password: z
        .string()
        .min(1, { message: 'Re-Type Password is required' })
    })
    .refine((data) => number.test(data.password), {
      message: t('invalidPasswordErrMsg'),
      path: ['password']
    })
    .refine((data) => symbol.test(data.password), {
      message: t('invalidPasswordErrMsg'),
      path: ['password']
    })
    .refine((data) => data.password.toLowerCase() !== data.password, {
      message: t('invalidPasswordErrMsg'),
      path: ['password']
    })
    .refine((data) => data.password.toUpperCase() !== data.password, {
      message: t('invalidPasswordErrMsg'),
      path: ['password']
    })
    .refine((data) => data.password === data.confirm_password, {
      message: 'Confirm password does not match',
      path: ['confirm_password']
    });

  type ResetPasswordFormSchemaPayload = z.infer<typeof ResetPasswordFormSchema>;

  //Form Variables
  const { control, formState, handleSubmit, setValue } =
    useForm<ResetPasswordFormSchemaPayload>({
      resolver: zodResolver(ResetPasswordFormSchema),
      mode: 'onChange'
    });
  const { errors, isValid } = formState;

  //State
  const [showPassword, setShowPassword] = useState(false);
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [loading, setLoading] = useState(false);

  //Methods
  const onSubmit = (formData: ResetPasswordFormSchemaPayload) => {
    const payload = {
      current_password: formData?.current_password,
      new_password: formData?.password
    };
    resetCurrentUserPassword(payload);
  };

  const resetCurrentUserPassword = async (payload: Object) => {
    setLoading(true);
    try {
      const result = await resetPassword(payload);
      if (result && Object.keys(result).length) {
        showSuccessMessage(result?.message, { theme: 'colored' });
        handleClose();
      } else {
        showErrorMessage(t('somethingWentWrong'), { theme: 'colored' });
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const getResetPasswordForm = () => {
    return (
      <>
        <Box sx={{ mb: 3 }}>{t('resetPasswordScreen.subTitle')}</Box>
        <Box className="w-100">
          <form>
            <Box className="flex-column-center align-items-start">
              <Box sx={{ mb: 2 }} className="w-100">
                <Controller
                  name="current_password"
                  defaultValue=""
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      label={t('set_password.currentPassword')}
                      type={showCurrentPassword ? 'text' : 'password'}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={() =>
                                setShowCurrentPassword((prev) => !prev)
                              }
                              edge="end"
                            >
                              {showPassword ? (
                                <VisibilityIcon />
                              ) : (
                                <VisibilityOffIcon />
                              )}
                            </IconButton>
                          </InputAdornment>
                        )
                      }}
                      variant="outlined"
                      required={true}
                      error={Boolean(errors?.current_password)}
                      helperText={errors?.current_password?.message}
                    />
                  )}
                />
              </Box>
              <Box sx={{ mb: 2 }} className="w-100">
                <Controller
                  name="password"
                  defaultValue=""
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      label={t('set_password.password')}
                      type={showPassword ? 'text' : 'password'}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={() => setShowPassword((prev) => !prev)}
                              edge="end"
                            >
                              {showPassword ? (
                                <VisibilityIcon />
                              ) : (
                                <VisibilityOffIcon />
                              )}
                            </IconButton>
                          </InputAdornment>
                        )
                      }}
                      variant="outlined"
                      required={true}
                      error={Boolean(errors?.password)}
                      helperText={errors?.password?.message}
                    />
                  )}
                />
              </Box>
              <Box sx={{ mb: 5 }} className="w-100">
                <Controller
                  name="confirm_password"
                  defaultValue=""
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      label={t('set_password.reTypePassword')}
                      type={showConfirmPassword ? 'text' : 'password'}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={() =>
                                setShowConfirmPassword((prev) => !prev)
                              }
                              edge="end"
                            >
                              {showConfirmPassword ? (
                                <VisibilityIcon />
                              ) : (
                                <VisibilityOffIcon />
                              )}
                            </IconButton>
                          </InputAdornment>
                        )
                      }}
                      variant="outlined"
                      required={true}
                      error={Boolean(errors?.confirm_password)}
                      helperText={errors?.confirm_password?.message}
                    />
                  )}
                />
              </Box>
            </Box>
            <Box className="flex-column-center">
              {loading ? (
                <CircularProgress sx={{ mb: 2 }} color="primary" />
              ) : (
                <Button
                  sx={{ mb: 1 }}
                  width="button-w-280"
                  btnText={t('update')}
                  disabled={!isValid}
                  variant="contained"
                  onClick={handleSubmit(onSubmit)}
                />
              )}
            </Box>
          </form>
        </Box>
      </>
    );
  };

  //HTML
  return (
    <SimpleDialog
      open={showResetPasswordDialog}
      fullWidth
      maxWidth="sm"
      model_title={
        <Box className="flex-basic-space-between">
          <Box>{t('resetPassword')}</Box>
          <CloseIcon
            className="cursorPointer"
            fontSize="small"
            onClick={handleClose}
          />
        </Box>
      }
      model_content={getResetPasswordForm()}
    />
  );
};

export default ResetPasswordDialog;
