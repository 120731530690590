import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from 'src/store/reducer';
import { searchJobs } from '../api/jobsApi';
import { JobsListingType } from '../models/jobs';

interface JobsService {
  loading: boolean;
  error: boolean;
  errorObj: Object;
  jobList: JobsListingType[];
}

const initialState: JobsService = {
  loading: false,
  error: false,
  errorObj: {},
  jobList: [] as JobsListingType[]
};

export const selectJobsDetails = ({ jobsData }: RootState) => ({
  loading: jobsData.loading,
  error: jobsData.error,
  jobList: jobsData.jobList
});

export const getJobsList = createAsyncThunk(
  'jobs/getJobsList',
  async (payload: Object) => {
    const result = await searchJobs(payload);
    return result;
  }
);

export const jobsSlice = createSlice({
  name: 'jobs',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getJobsList.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(getJobsList.fulfilled, (state, action) => {
        state.loading = false;
        state.error = true;
        if (action.payload) {
          state.jobList = action.payload?.jobs;
        }
      })
      .addCase(getJobsList.rejected, (state) => {
        state.loading = false;
        state.error = true;
      });
  }
});

export const {} = jobsSlice.actions;

export default jobsSlice.reducer;
