import { Box, Container, Grid, Link } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { zodResolver } from '@hookform/resolvers/zod';
import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';
import z from 'zod';

import {
  Button,
  CircularProgress,
  TextField,
  showErrorMessage
} from '../../shared/components/index';
import { SUPPORT_EMAILS } from 'src/shared/constants/constants';
import { useRouter } from 'src/providers/RouterProvider';
import * as ROUTES from '../../shared/constants/routes';
import {
  selectAuthDetail,
  sendForgotPasswordEmail,
  setForgotPasswordEmail
} from './service/auth.service';
import ForgotPasswordSuccess from './components/ForgotPasswordSuccess';

const ForgotPassword = () => {
  //Constants
  const { t } = useTranslation('english');
  const { routeTo } = useRouter();
  const dispatch = useDispatch<any>();

  //Zod Schema
  const loginFormSchema = z.object({
    email: z
      .string()
      .min(1, { message: 'Email address is required' })
      .email({ message: 'Please enter valid email' })
  });

  type LoginFormSchemaPayload = z.infer<typeof loginFormSchema>;

  //Form Variables
  const { control, formState, setValue, reset, handleSubmit } =
    useForm<LoginFormSchemaPayload>({
      resolver: zodResolver(loginFormSchema),
      mode: 'onChange'
    });
  const { errors, isValid } = formState;

  //Redux
  const { forgotPasswordDetails, loading } = useSelector(selectAuthDetail);

  //State
  const [showForgotPasswordSuccess, setShowForgotPasswordSuccess] =
    useState(false);

  //useEffects
  useEffect(() => {
    if (forgotPasswordDetails && Object.keys(forgotPasswordDetails).length) {
      if (forgotPasswordDetails.is_email_verified) {
        setShowForgotPasswordSuccess(true);
      } else {
        showErrorMessage(forgotPasswordDetails?.message, {
          theme: 'colored',
          position: 'bottom-center',
          autoClose: 3000
        });
      }
    }
  }, [forgotPasswordDetails]);

  //Method
  const onSubmit = (formData) => {
    dispatch(setForgotPasswordEmail(formData?.email));
    dispatch(sendForgotPasswordEmail(formData));
  };

  return (
    <Container maxWidth={'xl'} disableGutters>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          {showForgotPasswordSuccess ? (
            <ForgotPasswordSuccess />
          ) : (
            <Box sx={{ px: isMobile ? 3 : 15, pt: 5 }}>
              <Box className="flex-column-center w-100">
                <Box sx={{ mt: 8, mb: 4 }} className="text-h2 text-grey-2">
                  {t('forgetPassword.title')}
                </Box>
                <Box
                  sx={{ mb: 10, px: isMobile ? 0 : 8 }}
                  className="textalign text-large-regular text-grey-2"
                >
                  {t('forgetPassword.subTitle')}
                </Box>
                <Box
                  className={clsx({ ['w-100']: isMobile, ['w-50']: !isMobile })}
                >
                  <form>
                    <Box className="flex-column-center">
                      <Box sx={{ mb: 2 }} className="w-100">
                        <Controller
                          name="email"
                          defaultValue=""
                          control={control}
                          render={({ field }) => (
                            <TextField
                              {...field}
                              fullWidth
                              label={t('emailAddress')}
                              variant="outlined"
                              error={Boolean(errors?.email)}
                              helperText={errors?.email?.message}
                              required={true}
                            />
                          )}
                        />
                      </Box>
                    </Box>
                    <Box sx={{ my: 2 }} className="flex-column-center">
                      {loading ? (
                        <CircularProgress sx={{ mb: 2 }} color="primary" />
                      ) : (
                        <Button
                          sx={{ mb: 2 }}
                          btnText={t('Submit')}
                          disabled={!isValid}
                          width="button-w-280"
                          variant="contained"
                          onClick={handleSubmit(onSubmit)}
                        />
                      )}
                      <Button
                        sx={{ mb: 2 }}
                        btnText={t('back')}
                        width="button-w-280"
                        variant="text"
                        onClick={() => {
                          routeTo(ROUTES.LOGIN);
                        }}
                      />
                    </Box>
                  </form>
                  <Link
                    className="flex-basic-center text-medium-semibold text-primary cursorPointer textDecorationNone"
                    href={`mailto: ${SUPPORT_EMAILS.HELP_ASSISTANCE}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    sx={{ mt: 12, mb: 3 }}
                  >
                    {t('set_password.helpAndAssistance')}
                  </Link>
                </Box>
              </Box>
            </Box>
          )}
        </Grid>
      </Grid>
    </Container>
  );
};

export default ForgotPassword;
