import { useEffect, useRef, useState } from 'react';
import { Avatar, Box, Divider, IconButton, Popover } from '@mui/material';
import ExpandMoreTwoToneIcon from '@mui/icons-material/ExpandMoreTwoTone';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@mui/styles';

import { useAuth } from 'src/providers/AuthguardContext';
import { Typography } from 'src/shared/components/index';
import {
  clearSetPasswordDetails,
  selectAuthDetail
} from 'src/auth/login/service/auth.service';
import ResetPasswordDialog from 'src/auth/login/ResetPasswordDialog';

const useStyles = makeStyles({
  popOverStyle: {
    '& .MuiPaper-root': {
      borderRadius: '0 !important'
    }
  }
});

const HeaderUserbox = () => {
  const { logout } = useAuth();
  const ref = useRef<HTMLButtonElement>(null);
  const { t } = useTranslation('english');
  const classes = useStyles();
  const dispatch = useDispatch<any>();
  const options = [
    {
      id: 'reset-password',
      icon: 'icon-reset-password',
      label: 'resetPassword'
    },
    {
      id: 'logout',
      icon: 'icon-logout',
      label: 'logout'
    }
  ];

  const [isOpen, setOpen] = useState<boolean>(false);
  const [user, setUser] = useState<{ name: string; avatar: string }>(
    {} as { name: string; avatar: string }
  );
  const [showResetPasswordDialog, setShowResetPasswordDialog] = useState(false);

  const { userDetails } = useSelector(selectAuthDetail);

  useEffect(() => {
    if (userDetails && Object.keys(userDetails).length) {
      const data = {
        name: `${userDetails?.first_name || 'Jane'} ${
          userDetails?.last_name || 'Smith'
        }`,
        avatar: userDetails?.avatar_url
      };
      setUser(data);
    }
  }, [userDetails]);

  const handleOpen = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  const navigateTo = (item) => {
    switch (item?.id) {
      case 'reset-password':
        handleClose();
        setShowResetPasswordDialog(true);
        break;
      case 'logout':
        userLogout();
        break;
      default:
        break;
    }
  };

  const userLogout = () => {
    logout();
  };

  const closeResetPasswordDialog = () => {
    dispatch(clearSetPasswordDetails());
    setShowResetPasswordDialog(false);
  };

  return (
    <>
      <Box ref={ref} className="flex-basic-center">
        {user?.avatar && <Avatar alt={user.name} src={user.avatar} />}
        {!user.avatar && (
          <Box className="user-box text-h3-regular">
            <Box className="icon-user" sx={{ p: 2 }} />
          </Box>
        )}
        <Box className="UserBoxText">
          <Typography className="UserBoxLabel" variant="body1">
            {user.name}
          </Typography>
        </Box>
        <IconButton onClick={handleOpen} sx={{ ml: 1 }}>
          <ExpandMoreTwoToneIcon />
        </IconButton>
      </Box>
      <Popover
        className={classes.popOverStyle}
        sx={{ mt: 11 }}
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        <Box sx={{ m: 1 }}>
          {options?.map((item, index) => (
            <Box key={index} sx={{ px: 1 }} onClick={() => navigateTo(item)}>
              <Box
                sx={{ py: 1, pr: 6 }}
                className="flex-basic-start text-large-regular cursorPointer"
              >
                <Box className={item.icon} sx={{ mr: 1 }} />
                <Box>{t(item?.label)}</Box>
              </Box>
              <Divider sx={{ mt: 1 }} />
            </Box>
          ))}
        </Box>
      </Popover>

      {showResetPasswordDialog && (
        <ResetPasswordDialog
          showResetPasswordDialog={showResetPasswordDialog}
          handleClose={closeResetPasswordDialog}
        />
      )}
    </>
  );
};

export default HeaderUserbox;
