export const USER_SERVICE_URL = process.env.REACT_APP_USER_SERVICE_URL;
export const AUTH_SERVICE_URL = process.env.REACT_APP_AUTH_SERVICE_URL;

export const ACCESS_TOKEN = `ACCESS_TOKEN`;
export const FORGOT_PASSWORD_TOKEN = `FORGOT_PASSWORD_TOKEN`;

export const BUILD_NUMBER = '0.1.0';

export const LOGIN = import('src/auth/login/Login');
export const SIGNUP = import('src/auth/login/SignUp');

export const DASHBOARD = import('src/modules/dashboard/Dashboard');
export const JOBS = import('src/modules/jobs/Jobs');
export const ERROR_PAGE = import('src/modules/ErrorPage/ErrorPage');

export const COMPLETED = 'completed';
export const SUCCESS = 'success';
export const ERROR = 'error';
export const WARNING = 'warning';
export enum statusLable {
  COMPLETED = 'completed',
  PENDING = 'pending',
  FAILED = 'failed'
}

export const MAX_CHAR_LIMIT = {
  BASIC_CHAR_LENGTH: 20,
  CHAR_LENGTH_THIRTY: 30,
  CHAR_LENGTH_TWO_HUNDRED: 200,
  CHAR_LENGTH_THREE_HUNDRED: 300
};

export const PASSWORD_STRENGTH_LABEL_BY_SCORE: Record<number, string> = {
  0: 'weak',
  1: 'weak',
  2: 'fair',
  3: 'good',
  4: 'strong'
};

export const PRIVACY_POLICY =
  'https://s3.amazonaws.com/dev-qa.static.bucket/TrustTap-Privacy-Policy.pdf';

export const TRELLIS_DOCUMENT =
  'https://s3.amazonaws.com/dev-qa.static.bucket/What-is-Trellis.pdf';

export const TERMS_OF_SERVICE =
  'https://s3.amazonaws.com/dev-qa.static.bucket/TrustTap-Terms-of-Service.pdf';

export const SUPPORT_EMAILS = {
  HELP_ASSISTANCE: 'trusttap@trellishelp.zendesk.com'
};

export const REGEX = {
  EMAIL:
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  NUMBER_DECIMAL:
    /^(?:\d{1,2}(?:\.\d{1,2})?|100(?:\.0{1,2})?|0(?:\.\d{1,2})?)$/,
  NUMBER_INTEGER: /^(?:\d*[1-9]\d*|)$/,
  TEXT_ONLY: /^[a-zA-Z ]*$/,
  PASSWORD:
    /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[!@#$%^&*()\-_=+{};:,<.>])(?!.*\s).{8,}$/,
  JSON: /^[\],:{}\s]*$|^"(.|\\[\\"/bfnrt])*"$/
};

export const MEGABYTE_CONVERTER = 1000000;

export const FIELD_TYPE = {
  TEXT: 'text',
  RADIO: 'radio',
  EMAIL: 'email',
  SWITCH: 'switch',
  SELECT: 'select',
  REGEX: 'regex',
  PASSWORD: 'password',
  CHECKBOX: 'checkbox',
  TEXTAREA: 'textarea',
  NUMBER_ONLY: 'number_only',
  INTEGER_ONLY: 'integer_only',
  MULTI_SELECT: 'multi-select',
  AUTOCOMPLETE: 'autocomplete',
  CHECKBOX_GROUP: 'checkbox_group'
};
