import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { Grid, Box } from '@mui/material';

import onboardingLogo from '../../assets/images/onboarding.svg';
import SetPasswordForm from './components/SetPasswordForm';
import SetPasswordSuccess from './components/SetPasswordSuccess';

const SetPassword = () => {
  // Constants
  const { t } = useTranslation('english');

  // State Variables
  const [isProcessCompleted, setIsProcessCompleted] = useState<boolean>(false);

  // Methods
  const getCreateAccountStatus = (isSubmittedSuccessfully: boolean) => {
    if (isSubmittedSuccessfully) {
      setIsProcessCompleted(isSubmittedSuccessfully);
    } else {
      toast('Something went wrong, please try again later.');
    }
  };

  return (
    <>
      {isProcessCompleted ? (
        <SetPasswordSuccess />
      ) : (
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
            <SetPasswordForm getCreateAccountStatus={getCreateAccountStatus} />
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            <Box className="flex-column-center onboarding-right-section">
              <Box sx={{ mr: 12, mt: 10 }}>
                <img src={onboardingLogo} width="470" height="470" />
              </Box>
            </Box>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default SetPassword;
