import React from 'react';
import { isMobile } from 'react-device-detect';
import { Box, Grid, Container } from '@mui/material';
import clsx from 'clsx';

import {
  BUILD_NUMBER,
  PRIVACY_POLICY,
  TRELLIS_DOCUMENT
} from 'src/shared/constants/constants';
import { Button } from 'src/shared/components/button/Button';
import trellisLogo from '../../assets/images/trellis_logo.svg';

const Footer = () => {
  return (
    <Container maxWidth="xl" disableGutters>
      <Grid container>
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          <Box className={clsx({ ['flex-basic-center']: isMobile })}>
            <Button
              btnText="Privacy Policy"
              variant="text"
              onClick={() => window.open(PRIVACY_POLICY, '_blank')}
            />
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          <Box className="flex-basic-center" sx={{ mt: 1 }}>
            <Box className="flex-basic-center" sx={{ mr: 1 }}>
              Powered By
              <img src={trellisLogo} />
            </Box>
            <span className="text-primary text-medium-semibold">
              {`v${BUILD_NUMBER}`}
            </span>
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          <Box
            className={`${isMobile ? 'flex-basic-center' : 'flex-basic-end'}`}
          >
            <Button
              btnText="What is Trellis?"
              variant="text"
              onClick={() => window.open(TRELLIS_DOCUMENT, '_blank')}
            />
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Footer;
