import { Scrollbar } from 'src/shared/components/index';
import { Box, useTheme, useMediaQuery } from '@mui/material';

import SidebarMenu from './sidebar-menu/SidebarMenu';
import trustTapLogo from '../../assets/images/trusttap_logo.svg';

const Sidebar = () => {
  const theme = useTheme();
  const isLg = useMediaQuery(theme.breakpoints.up('lg'));
  const classes = isLg ? 'large SidebarWrapper ' : 'small SidebarWrapper';
  const wrapperclass = 'sidebarwrapperLight';
  const finalclass = `${classes} ${wrapperclass}`;
  return (
    <>
      <Box className={finalclass}>
        <Scrollbar>
          <Box m={4} className="flex-basic-center">
            <img src={trustTapLogo} />
          </Box>
          <SidebarMenu />
        </Scrollbar>
      </Box>
    </>
  );
};

export default Sidebar;
