export const LOGIN = '/';
export const ERROR_PAGE = '/not-found';

export const DASHBOARD = 'dashboard';
export const JOBS = 'jobs';

export const ONBOARDING = 'onboarding';
export const CREATE_ACCOUNT = 'create-account';
export const RESEND_EMAIL = 'resend-email';
export const RESEND_EMAIL_ERROR = 'resend-email-error';
export const FORGOT_PASSWORD = 'forgot-password';
export const RESET_PASSWORD = 'reset-password';
