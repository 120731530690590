import { useTranslation } from 'react-i18next';
import { Box, List, ListItem, ListItemIcon } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useRouter } from 'src/providers/RouterProvider';
interface sidebarmenuprops {
  options: SideBarType[];
}

export interface SideBarType {
  id: number;
  pathname: string;
  icon: JSX.Element;
  title: string;
}

const useStyles = makeStyles({
  iconStyle: {
    '&.MuiListItemIcon-root': {
      minWidth: 0
    }
  }
});

export const SidebarMenus = ({ options }: sidebarmenuprops) => {
  //Constants
  const classes = useStyles();
  const { t } = useTranslation('english');
  const { routeTo } = useRouter();
  const location = useLocation();

  //State
  const [activeItem, setActiveItem] = useState(0);

  //useEffect
  useEffect(() => {
    getActiveItem();
  }, [location, options]);

  // Methods
  const getActiveItem = () => {
    const findIndex = options?.findIndex((item) =>
      location?.pathname?.includes(item?.pathname)
    );
    setActiveItem(findIndex);
  };

  const handleListItemClick = (item: any) => {
    getActiveItem();
    routeTo(item?.pathname);
  };

  return (
    <>
      <List component="div" sx={{ pt: 2 }}>
        {options.map((item, index) => {
          return (
            <ListItem
              component="div"
              key={index}
              className={clsx('cursorPointer', {
                ['flex-basic-center']: true,
                ['navItemActive']: activeItem === item?.id
              })}
              onClick={() => handleListItemClick(item)}
            >
              <ListItemIcon
                className={clsx({
                  [classes.iconStyle]: true,
                  ['text-grey-3']: true,
                  ['text-white']: activeItem === item?.id
                })}
              >
                {item.icon}
              </ListItemIcon>
              <Box
                className={clsx({
                  ['text-medium-bold text-grey-3']: true,
                  ['text-white']: activeItem === item?.id
                })}
                sx={{ ml: 2 }}
              >
                {t(item.title)}
              </Box>
            </ListItem>
          );
        })}
      </List>
    </>
  );
};
