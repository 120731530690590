import React from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Grid } from '@mui/material';
import onboardingSuccess from '../../../assets/images/success.svg';
import onboardingLogo from '../../../assets/images/onboarding.svg';
import { useRouter } from 'src/providers/RouterProvider';
import * as ROUTES from '../../../shared/constants/routes';
import { Button } from 'src/shared/components/button/Button';

const SetPasswordSuccess = () => {
  // Constants
  const { t } = useTranslation('english');
  const { routeTo } = useRouter();

  // Methods
  const navigateToLogin = () => {
    routeTo(ROUTES.DASHBOARD);
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
        <Box sx={{ p: 16 }}>
          <Box sx={{ px: 8 }} className="flex-basic-center">
            <img src={onboardingSuccess} />
          </Box>
          <Box sx={{ px: 10, py: 6 }} className="flex-column-center">
            <Box className="text-h2" sx={{ mb: 1 }}>
              {t('set_password.congratulations')}
            </Box>
            <Box className="text-h3">
              {t('set_password.emailVerifiedMessage')}
            </Box>
          </Box>
          <Box
            sx={{ px: 3 }}
            className="flex-column-center text-medium-regular text-center"
          >
            <Box sx={{ mb: 1 }}>
              {t('set_password.jobPortalCreatedMessage')}
            </Box>
            <Box>{t('set_password.clickBelowToLogin')}</Box>
          </Box>
          <Box
            sx={{ p: 8 }}
            className="flex-column-center text-medium-regular text-center"
          >
            <Button
              btnText={t('set_password.okay')}
              width="button-w-280"
              variant="contained"
              onClick={navigateToLogin}
            />
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
        <Box className="flex-column-center onboarding-right-section">
          <Box sx={{ mr: 12, mt: 10 }}>
            <img src={onboardingLogo} width="470" height="470" />
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export default SetPasswordSuccess;
