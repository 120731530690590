import { Suspense, lazy } from 'react';
import { RouteObject } from 'react-router';
import SidebarLayout from 'src/core/layout/SidebarLayout';
import BaseLayout from 'src/core';

import { GuardedRoute } from './guarded-routes';
import {
  LOGIN,
  SIGNUP,
  DASHBOARD,
  ERROR_PAGE,
  JOBS
} from './../shared/constants/constants';

import { SuspenseLoader } from 'src/shared/components/index';
import * as ROUTES from '../shared/constants/routes';
import OnboardingScreen from 'src/modules/Onboarding/OnboardingScreen';
import SetPassword from 'src/modules/set-password/SetPassword';
import ResendEmail from 'src/modules/resend-email/ResendEmail';
import ResendEmailError from 'src/modules/resend-email/components/ResendEmailError';
import ForgotPassword from 'src/auth/login/ForgotPassword';
import ResetPassword from 'src/auth/login/ResetPassword';

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

// Pages

const Login = Loader(lazy(() => LOGIN));
const ErrorPage = Loader(lazy(() => ERROR_PAGE));

const SignUp = Loader(lazy(() => SIGNUP));

// Dashboards
const Dashboard = Loader(lazy(() => DASHBOARD));
const Jobs = Loader(lazy(() => JOBS));

const routes: RouteObject[] = [
  {
    path: '',
    element: <BaseLayout />,
    children: [
      {
        path: ROUTES.LOGIN,
        element: <Login />
      }
    ]
  },
  {
    path: '',
    element: <BaseLayout />,
    children: [
      {
        path: ROUTES.ONBOARDING,
        element: <OnboardingScreen />
      }
    ]
  },
  {
    path: '',
    element: <BaseLayout />,
    children: [
      {
        path: ROUTES.CREATE_ACCOUNT,
        element: <SetPassword />
      }
    ]
  },
  {
    path: '',
    element: <BaseLayout />,
    children: [
      {
        path: ROUTES.RESEND_EMAIL,
        element: <ResendEmail />
      }
    ]
  },
  {
    path: '',
    element: <BaseLayout />,
    children: [
      {
        path: ROUTES.RESEND_EMAIL_ERROR,
        element: <ResendEmailError />
      }
    ]
  },
  {
    path: '',
    element: <BaseLayout />,
    children: [
      {
        path: ROUTES.FORGOT_PASSWORD,
        element: <ForgotPassword />
      }
    ]
  },
  {
    path: '',
    element: <BaseLayout />,
    children: [
      {
        path: ROUTES.RESET_PASSWORD,
        element: <ResetPassword />
      }
    ]
  },
  {
    path: 'signup',
    element: <BaseLayout />,
    children: [
      {
        path: '',
        element: <SignUp />
      }
    ]
  },
  {
    path: ROUTES.DASHBOARD,
    element: <SidebarLayout />,
    children: [
      {
        path: '',
        element: <GuardedRoute component={Dashboard} />
      }
    ]
  },
  {
    path: ROUTES.JOBS,
    element: <SidebarLayout />,
    children: [
      {
        path: '',
        element: <GuardedRoute component={Jobs} />
      }
    ]
  },
  {
    path: ROUTES.ERROR_PAGE,
    element: <ErrorPage />
  }
];

export default routes;
