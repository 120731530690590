import { Box, Chip, useMediaQuery, useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import z from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import CloseIcon from '@mui/icons-material/Close';

import {
  Button,
  CircularProgress,
  GPlaces,
  PhoneNumber,
  TextField,
  showErrorMessage
} from '../../../shared/components/index';
import {
  completeOnboarding,
  selectOnboardingDetail
} from '../service/onboarding.service';
import { OnbardingPayload } from '../models/onboardingInterface';
import * as ROUTES from '../../../shared/constants/routes';
import { useRouter } from 'src/providers/RouterProvider';
import { isMobile } from 'react-device-detect';

interface OnboardingFormProps {
  getOnboardingStatus: (status: boolean) => void;
}

const OnboardingForm = ({ getOnboardingStatus }: OnboardingFormProps) => {
  //Constants
  const theme = useTheme();
  const smallDevice = useMediaQuery(theme.breakpoints.down('sm'));
  const { t } = useTranslation('english');
  const dispatch = useDispatch<any>();
  const { routeTo } = useRouter();

  //Zod Schema
  const AddOwnerFormSchema = z.object({
    first_name: z
      .string()
      .min(1, { message: 'First name is required' })
      .max(50, {
        message: 'First name should be less than 50 characters'
      }),
    last_name: z.string().min(1, { message: 'Last name is required' }).max(50, {
      message: 'Last name should be less than 50 characters'
    }),
    email: z
      .string()
      .min(1, { message: 'Email address is required' })
      .email({ message: 'Please enter valid email address' })
      .max(50, {
        message: `Email address should be less than 50 characters`
      }),
    phone: z
      .string()
      .min(9, { message: 'Please enter valid phone number' })
      .refine(
        (value) => {
          const numericValue = value.replace(/\D/g, '');
          return /^\+?[1-9]\d{1,14}$/.test(numericValue);
        },
        {
          message: 'Invalid phone number format'
        }
      ),
    job_location: z.string(),
    job_type: z.string()
  });

  type AddOwnersFormSchemaPayload = z.infer<typeof AddOwnerFormSchema>;

  //Form Variables
  const { control, formState, setValue, reset, handleSubmit } =
    useForm<AddOwnersFormSchemaPayload>({
      resolver: zodResolver(AddOwnerFormSchema),
      mode: 'onChange'
    });
  const { errors, isValid } = formState;

  //State
  const [selectedCityStateList, setSelectedCityStateList] = useState<string[]>(
    []
  );
  const [prefferedLocationList, setPrefferedLocationList] = useState<string[]>(
    []
  );
  const [selectedJobType, setSelectedJobType] = useState<string[]>([]);
  const [jobDescriptionList, setJobDescriptionList] = useState<string[]>([]);

  //Redux
  const { onboardingDetail, loading } = useSelector(selectOnboardingDetail);

  //useEffect
  useEffect(() => {
    if (selectedCityStateList.length) {
      setValue('job_location', '', { shouldValidate: false });
      const data = [...new Set(selectedCityStateList)];
      setPrefferedLocationList(data);
    } else {
      setPrefferedLocationList([]);
    }
  }, [selectedCityStateList]);

  useEffect(() => {
    if (selectedJobType.length) {
      setValue('job_type', '', { shouldValidate: true });
      const data = [...new Set(selectedJobType)];
      setJobDescriptionList(data);
    } else {
      setJobDescriptionList([]);
    }
  }, [selectedJobType]);

  useEffect(() => {
    if (onboardingDetail && Object.keys(onboardingDetail).length) {
      if (!onboardingDetail?.is_email_verified) {
        reset();
        setSelectedCityStateList([]);
        setSelectedJobType([]);
        getOnboardingStatus(true);
      } else {
        showErrorMessage(onboardingDetail?.message, {});
      }
    }
  }, [onboardingDetail]);

  //Methods
  const onSubmit = (formData: AddOwnersFormSchemaPayload) => {
    const phoneNumber = formData?.phone.split(' ');
    const payload: OnbardingPayload = {
      first_name: formData?.first_name,
      last_name: formData?.last_name,
      email: formData?.email,
      phone_number: {
        country_code: phoneNumber[0],
        number: `${phoneNumber[1]} ${phoneNumber[2]}`
      },
      job_location: prefferedLocationList,
      job_type: jobDescriptionList
    };

    dispatch(completeOnboarding(payload));
  };

  const handleCityStateSelection = (placeData) => {
    const cityStateList = [...selectedCityStateList];
    if (cityStateList.includes(placeData?.name)) {
      const index = cityStateList.indexOf(placeData?.name);
      cityStateList.splice(index, 1);
    } else {
      cityStateList.push(placeData?.name);
    }
    setSelectedCityStateList((prev) => [...prev, ...cityStateList]);
  };

  const handleCityStateRemove = (position: number) => {
    const cityStateList: string[] = [...prefferedLocationList];
    cityStateList.splice(position, 1);
    if (cityStateList.length) {
      setSelectedCityStateList(cityStateList);
    } else {
      setSelectedCityStateList([]);
    }
  };

  const handleJobTypeChange = (event) => {
    if (event.key === 'Enter') {
      const jobTypeList = [...jobDescriptionList];
      if (
        jobTypeList.some(
          (item) =>
            item.trim().toLowerCase() ===
            event?.target?.value?.trim().toLowerCase()
        )
      ) {
        const index = jobTypeList.indexOf(event.target.value);
        jobTypeList.splice(index, 1);
      } else {
        if (event?.target?.value) {
          jobTypeList.push(event.target.value);
        }
      }
      setSelectedJobType((prev) => [...prev, ...jobTypeList]);
    }
  };

  const handleJobTypeRemove = (position: number) => {
    const jobTypeList: string[] = [...jobDescriptionList];
    jobTypeList.splice(position, 1);
    if (jobTypeList.length) {
      setSelectedJobType(jobTypeList);
    } else {
      setSelectedJobType([]);
    }
  };

  return (
    <Box sx={{ px: smallDevice ? 3 : 15 }}>
      <Box className="flex-column-center w-100">
        <Box sx={{ mt: 8, mb: 4 }} className="text-h2 text-grey-2">
          {t('onboarding.leftSectionTitle')}
        </Box>
        <Box
          sx={{ mb: 4, px: isMobile ? 0 : 8 }}
          className="textalign text-large-semibold text-grey-2"
        >
          {t('onboarding.leftSectionSubTitle')}
        </Box>
        <Box className="w-100">
          <form>
            <Box className="flex-column-center align-items-start">
              <Box sx={{ mb: 2 }} className="w-100">
                <Controller
                  name="first_name"
                  defaultValue=""
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      label={t('onboarding.firstName')}
                      variant="outlined"
                      error={Boolean(errors?.first_name)}
                      helperText={errors?.first_name?.message}
                      required={true}
                    />
                  )}
                />
              </Box>
              <Box sx={{ mb: 2 }} className="w-100">
                <Controller
                  name="last_name"
                  defaultValue=""
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      label={t('onboarding.lastName')}
                      variant="outlined"
                      error={Boolean(errors?.last_name)}
                      helperText={errors?.last_name?.message}
                      required={true}
                    />
                  )}
                />
              </Box>
              <Box sx={{ mb: 2 }} className="w-100">
                <Controller
                  name="email"
                  defaultValue=""
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      label={t('onboarding.email')}
                      variant="outlined"
                      error={Boolean(errors?.email)}
                      helperText={errors?.email?.message}
                      required={true}
                    />
                  )}
                />
              </Box>
              <Box sx={{ mb: 2 }} className="w-100">
                <Controller
                  name="phone"
                  defaultValue=""
                  control={control}
                  render={({ field }) => (
                    <PhoneNumber
                      label={`${t('onboarding.phoneNumber')}`}
                      variant="outlined"
                      fullWidth
                      required
                      InputLabelProps={{
                        classes: {
                          asterisk: 'custom-asterisk'
                        }
                      }}
                      onlyCountries={['us']}
                      disableAreaCodes={true}
                      countryCodeEditable={false}
                      className="textFieldPhoneNumber"
                      {...field}
                    />
                  )}
                />
                {errors.phone && (
                  <Box sx={{ pl: 1, color: 'red', fontWeight: 800 }}>
                    {errors.phone.message}
                  </Box>
                )}
              </Box>
              <Box sx={{ mb: 2 }} className="w-100">
                <Controller
                  name="job_location"
                  defaultValue=""
                  control={control}
                  render={({ field, fieldState }) => (
                    <GPlaces
                      fieldId={'line1'}
                      placeholder={t('onboarding.preferredJobLocation')}
                      fieldType={[]}
                      label={`${t('onboarding.preferredJobLocation')}`}
                      variant="outlined"
                      required
                      fullWidth
                      sx={{ my: 1 }}
                      error={
                        fieldState.isTouched && !prefferedLocationList.length
                      }
                      helperText={
                        fieldState.isTouched &&
                        !prefferedLocationList.length &&
                        t('onboarding.prefferedJobLocationRequiredErrMsg')
                      }
                      disabled={false}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                          setSelectedCityStateList(prefferedLocationList);
                        }
                      }}
                      onPlaceSelection={handleCityStateSelection}
                      {...field}
                    />
                  )}
                />
                <Box className="customChip">
                  {prefferedLocationList.length > 0 &&
                    prefferedLocationList.map((item, index) => (
                      <Chip
                        key={index}
                        sx={{ mr: 1, mb: 1 }}
                        label={item}
                        deleteIcon={<CloseIcon />}
                        onDelete={() => handleCityStateRemove(index)}
                      />
                    ))}
                </Box>
              </Box>
              <Box sx={{ mb: 2 }} className="w-100">
                <Controller
                  name="job_type"
                  defaultValue=""
                  control={control}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      fullWidth
                      label={`${t('onboarding.jobDescription')}`}
                      required
                      variant="outlined"
                      placeholder={`${t(
                        'onboarding.jobDescriptionPlaceholder'
                      )}`}
                      onKeyDown={handleJobTypeChange}
                      error={fieldState.isTouched && !jobDescriptionList.length}
                      helperText={
                        fieldState.isTouched &&
                        !jobDescriptionList.length &&
                        t('onboarding.jobDescriptionRequiredErrMsg')
                      }
                    />
                  )}
                />
                <Box className="customChip" sx={{ mt: 1 }}>
                  {jobDescriptionList.length > 0 &&
                    jobDescriptionList.map((item, index) => (
                      <Chip
                        key={index}
                        sx={{ mr: 1, mb: 1 }}
                        label={item}
                        deleteIcon={<CloseIcon />}
                        onDelete={() => handleJobTypeRemove(index)}
                      />
                    ))}
                </Box>
              </Box>
            </Box>
            <Box sx={{ my: 2 }} className="flex-column-center">
              {loading ? (
                <CircularProgress sx={{ mb: 2 }} color="primary" />
              ) : (
                <Button
                  sx={{ mb: 2 }}
                  btnText={t('onboarding.createJobSearchPortal')}
                  disabled={
                    !isValid ||
                    prefferedLocationList.length === 0 ||
                    jobDescriptionList.length === 0
                  }
                  variant="contained"
                  onClick={handleSubmit(onSubmit)}
                />
              )}

              <Button
                sx={{ mb: 2 }}
                btnText={t('onboarding.dontHaveEmail')}
                variant="text"
                onClick={() => {
                  routeTo(ROUTES.RESEND_EMAIL);
                }}
              />
            </Box>
          </form>
        </Box>
      </Box>
    </Box>
  );
};

export default OnboardingForm;
