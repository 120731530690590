import { AnyAction, combineReducers } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import onboardingReducer from '../../modules/Onboarding/service/onboarding.service';
import setPasswordReducer from '../../modules/set-password/service/setPassword.service';
import resendEmailReducer from '../../modules/resend-email/service/resendEmail.service';
import authReducer from '../../auth/login/service/auth.service';
import jobsReducer from '../../modules/jobs/service/jobs.service';

const combinedReducer = combineReducers({
  onboardingData: onboardingReducer,
  setPasswordData: setPasswordReducer,
  resendEmailData: resendEmailReducer,
  authData: authReducer,
  jobsData: jobsReducer
});

export type RootState = ReturnType<typeof combinedReducer>;

const rootReducer = (state: RootState, action: AnyAction) => {
  if (action.type.indexOf('/clearResults') > -1) {
    // this applies to all keys defined in persistConfig(s)
    storage.removeItem('persist:root');

    state = {} as RootState;
  }
  return combinedReducer(state, action);
};

export default rootReducer;
