import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from 'src/store/reducer';
import { forgotPassword, setPassword, userLogin } from '../api/authApi';
import {
  ForgotPasswordDetail,
  SetPasswordDetail,
  Token,
  UserDetails
} from '../model/authInterface';
import { showSuccessMessage } from '../../../shared/components/index';

interface AuthDetails {
  loading: boolean;
  error: boolean;
  errorObj: Object;
  token: Token;
  userDetails: UserDetails;
  forgotPasswordDetails: ForgotPasswordDetail;
  forgotPasswordEmail: string;
  setPasswordDetails: SetPasswordDetail;
}

const initialState: AuthDetails = {
  loading: false,
  error: false,
  errorObj: {},
  token: <Token>{},
  userDetails: <UserDetails>{},
  forgotPasswordDetails: <ForgotPasswordDetail>{},
  forgotPasswordEmail: '',
  setPasswordDetails: <SetPasswordDetail>{}
};

export const selectAuthDetail = ({ authData }: RootState) => ({
  loading: authData.loading,
  error: authData.error,
  token: authData.token,
  userDetails: authData.userDetails,
  forgotPasswordDetails: authData.forgotPasswordDetails,
  forgotPasswordEmail: authData.forgotPasswordEmail,
  setPasswordDetails: authData.setPasswordDetails
});

export const userAuthentication = createAsyncThunk(
  'auth/userAuthentication',
  async (payload: Object) => {
    const result = await userLogin(payload);
    return result;
  }
);

export const sendForgotPasswordEmail = createAsyncThunk(
  'auth/sendForgotPasswordEmail',
  async (payload: Object) => {
    const result = await forgotPassword(payload);
    return result;
  }
);

export const resendForgotPasswordEmail = createAsyncThunk(
  'auth/resendForgotPasswordEmail',
  async (payload: Object) => {
    const result = await forgotPassword(payload);
    return result;
  }
);

export const setUserPassword = createAsyncThunk(
  'auth/resetUserPassword',
  async (payload: Object) => {
    const result = await setPassword(payload);
    return result;
  }
);

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setForgotPasswordEmail: (state, action) => {
      state.forgotPasswordEmail = action.payload;
    },
    clearSetPasswordDetails: (state) => {
      state.setPasswordDetails = <SetPasswordDetail>{};
    },
    clearForgotPasswordDetails: (state) => {
      state.forgotPasswordDetails = {} as ForgotPasswordDetail;
    }
  },
  extraReducers: (builder) =>
    builder
      .addCase(userAuthentication.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(userAuthentication.fulfilled, (state, action) => {
        state.loading = false;
        state.error = false;
        if (action.payload) {
          state.token = action.payload?.token;
          state.userDetails = action.payload?.user;
        }
      })
      .addCase(userAuthentication.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })
      .addCase(sendForgotPasswordEmail.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(sendForgotPasswordEmail.fulfilled, (state, action) => {
        state.loading = false;
        state.error = false;
        if (action.payload) {
          state.forgotPasswordDetails = action.payload;
        }
      })
      .addCase(sendForgotPasswordEmail.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })
      .addCase(resendForgotPasswordEmail.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(resendForgotPasswordEmail.fulfilled, (state, action) => {
        state.loading = false;
        state.error = false;
        if (action.payload) {
          showSuccessMessage(action.payload?.message, {
            theme: 'colored',
            position: 'bottom-center',
            autoClose: 3000
          });
        }
      })
      .addCase(resendForgotPasswordEmail.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })
      .addCase(setUserPassword.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(setUserPassword.fulfilled, (state, action) => {
        state.loading = false;
        state.error = false;
        if (action.payload) {
          state.setPasswordDetails = action.payload;
        }
      })
      .addCase(setUserPassword.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })
});

export const {
  setForgotPasswordEmail,
  clearSetPasswordDetails,
  clearForgotPasswordDetails
} = authSlice.actions;

export default authSlice.reducer;
