import { Box, Grid } from '@mui/material';
import React, { useEffect } from 'react';
import onboardingSuccess from '../../../assets/images/success.svg';
import { useTranslation } from 'react-i18next';
import { clearStorage } from 'src/shared/utils/storage';
import { clearOnboardingDetails } from '../service/onboarding.service';
import { useDispatch } from 'react-redux';

const OnboardingSuccess = () => {
  //Constants
  const { t } = useTranslation('english');
  const dispatch = useDispatch<any>();

  useEffect(() => {
    dispatch(clearOnboardingDetails());
  }, []);

  //HTML
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Box sx={{ py: 10 }} className="flex-column-center">
          <Box sx={{ mb: 6 }}>
            <img src={onboardingSuccess} alt="OnbardingSuccess" />
          </Box>
          <Box sx={{ mb: 1 }} className="text-h2 text-grey-2">
            {t('onboarding.greatJob')}
          </Box>
          <Box sx={{ mb: 3 }} className="text-h2 text-grey-2">
            {t('onboarding.jobSearchPortalReady')}
          </Box>
          <Box sx={{ mb: 10 }} className="text-medium-regular text-grey-2">
            {t('onboarding.successSubTitle')}
          </Box>
          <Box sx={{ mb: 6 }} className="text-h2 text-grey-2">
            {t('onboarding.whatsNext')}
          </Box>
          <Box sx={{ mb: 2 }} className="text-medium-regular text-grey-2">
            {t('onboarding.whatsNextPoint1')}
          </Box>
          <Box sx={{ mb: 2 }} className="text-medium-regular text-grey-2">
            {t('onboarding.whatsNextPoint2')}
          </Box>
          <Box className="text-medium-regular text-grey-2">
            {t('onboarding.whatsNextPoint3')}
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export default OnboardingSuccess;
