import { ACCESS_TOKEN, FORGOT_PASSWORD_TOKEN } from '../constants/constants';

export const setItem = (key: string, value: string) => {
  localStorage.setItem(key, value);
};

const getItem = (key: string) => localStorage.getItem(key) || null;

export const getAccessToken = () => getItem(ACCESS_TOKEN) || '';
export const getForgotPasswordToken = () =>
  getItem(FORGOT_PASSWORD_TOKEN) || '';

export const setAccessToken = (accessToken: string) =>
  setItem(ACCESS_TOKEN, accessToken);
export const setForgotPasswordToken = (token: string) =>
  setItem(FORGOT_PASSWORD_TOKEN, token);

export const clearStorage = () => {
  localStorage.clear();
  sessionStorage.clear();
};
