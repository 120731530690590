import React, { useEffect, useState } from 'react';
import {
  Box,
  CircularProgress,
  IconButton,
  InputAdornment,
  Link,
  useMediaQuery,
  useTheme
} from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import z from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

import { Button, CheckBox, TextField } from '../../../shared/components/index';
import {
  SUPPORT_EMAILS,
  TERMS_OF_SERVICE
} from 'src/shared/constants/constants';
import { useRouter } from 'src/providers/RouterProvider';
import * as ROUTES from '../../../shared/constants/routes';
import {
  selectSetPasswordDetail,
  validateUser,
  clearResults,
  userSignUp
} from '../service/setPassword.service';
import { useSelector } from 'react-redux';

interface SetPasswordFormProps {
  getCreateAccountStatus: (status: boolean) => void;
}

const SetPasswordForm = ({ getCreateAccountStatus }: SetPasswordFormProps) => {
  // Constants
  const theme = useTheme();
  const smallDevice = useMediaQuery(theme.breakpoints.down('sm'));
  const { t } = useTranslation('english');
  const { routeTo } = useRouter();
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');
  const dispatch = useDispatch<any>();
  const symbol = /[ `!@#$%^&*()_+\-=\]{};':"\\|,.<>?~]/;
  const number = /\d/;

  // State Variables
  const [termsAndConditions, setTermsAndConditions] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  // Redux Values
  const { validateTokenDetail, userDetail, loading } = useSelector(
    selectSetPasswordDetail
  );

  //Zod Schema
  const SetPasswordFormSchema = z
    .object({
      email: z
        .string()
        .min(1, { message: 'Email Address is required' })
        .email()
        .max(50, {
          message: `Email should be less than 50 characters`
        }),
      password: z
        .string()
        .min(1, { message: 'Password is required' })
        .min(9, {
          message: t('invalidPasswordErrMsg')
        })
        .max(16, { message: t('invalidPasswordErrMsg') }),
      confirm_password: z
        .string()
        .min(1, { message: 'Re-Type Password is required' })
    })
    .refine((data) => number.test(data.password), {
      message: t('invalidPasswordErrMsg'),
      path: ['password']
    })
    .refine((data) => symbol.test(data.password), {
      message: t('invalidPasswordErrMsg'),
      path: ['password']
    })
    .refine((data) => data.password.toLowerCase() !== data.password, {
      message: t('invalidPasswordErrMsg'),
      path: ['password']
    })
    .refine((data) => data.password.toUpperCase() !== data.password, {
      message: t('invalidPasswordErrMsg'),
      path: ['password']
    })
    .refine((data) => data.password === data.confirm_password, {
      message: 'Confirm password does not match',
      path: ['confirm_password']
    });

  type SetPasswordFormSchemaPayload = z.infer<typeof SetPasswordFormSchema>;

  //Form Variables
  const { control, formState, handleSubmit, setValue } =
    useForm<SetPasswordFormSchemaPayload>({
      resolver: zodResolver(SetPasswordFormSchema),
      mode: 'onChange'
    });
  const { errors, isValid } = formState;

  // Use Effects
  useEffect(() => {
    if (token) {
      const payload = {
        token: token
      };
      dispatch(validateUser(payload));
    }
    return () => {
      dispatch(clearResults());
    };
  }, []);

  useEffect(() => {
    if (validateTokenDetail?.email) {
      setValue('email', validateTokenDetail?.email, { shouldValidate: true });
    }
  }, [validateTokenDetail]);

  useEffect(() => {
    if (userDetail?.token?.access) {
      getCreateAccountStatus(true);
    }
  }, [userDetail]);

  // Methods
  const onSubmit = (formData: SetPasswordFormSchemaPayload) => {
    const payload = {
      email: formData?.email,
      password: formData?.password
    };
    dispatch(userSignUp(payload));
  };

  const handleTermsAndConditions = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setTermsAndConditions(event?.target?.checked);
  };

  const navigateToLogin = () => {
    routeTo(ROUTES.LOGIN);
  };

  return (
    <Box sx={{ px: smallDevice ? 3 : 25, py: 3 }}>
      <Box className="flex-column-center w-100">
        <Box sx={{ mt: 8, mb: 2 }} className="text-h2 textalign">
          {t('set_password.jobPortalMessage')}
        </Box>
        <Box sx={{ mb: 10 }} className="text-medium-regular textalign">
          {t('set_password.selectPassword')}
        </Box>
        <Box className="w-100">
          <form>
            <Box className="flex-column-center align-items-start">
              <Box sx={{ mb: 2 }} className="w-100">
                <Controller
                  name="email"
                  defaultValue=""
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      label={t('set_password.emailAddress')}
                      variant="outlined"
                      error={Boolean(errors?.email)}
                      disabled={!!token}
                      helperText={errors?.email?.message}
                      required={true}
                    />
                  )}
                />
              </Box>
              <Box sx={{ mb: 2 }} className="w-100">
                <Controller
                  name="password"
                  defaultValue=""
                  control={control}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      fullWidth
                      label={t('set_password.password')}
                      type={showPassword ? 'text' : 'password'}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={() => setShowPassword((prev) => !prev)}
                              edge="end"
                              color={
                                fieldState?.isDirty
                                  ? fieldState?.error
                                    ? 'error'
                                    : 'primary'
                                  : 'default'
                              }
                            >
                              {showPassword ? (
                                <VisibilityIcon />
                              ) : (
                                <VisibilityOffIcon />
                              )}
                            </IconButton>
                          </InputAdornment>
                        )
                      }}
                      variant="outlined"
                      required={true}
                      error={Boolean(errors?.password)}
                      helperText={errors?.password?.message}
                    />
                  )}
                />
              </Box>
              <Box sx={{ mb: 5 }} className="w-100">
                <Controller
                  name="confirm_password"
                  defaultValue=""
                  control={control}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      fullWidth
                      label={t('set_password.reTypePassword')}
                      type={showConfirmPassword ? 'text' : 'password'}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={() =>
                                setShowConfirmPassword((prev) => !prev)
                              }
                              edge="end"
                              color={
                                fieldState?.isDirty
                                  ? fieldState?.error
                                    ? 'error'
                                    : 'primary'
                                  : 'default'
                              }
                            >
                              {showConfirmPassword ? (
                                <VisibilityIcon />
                              ) : (
                                <VisibilityOffIcon />
                              )}
                            </IconButton>
                          </InputAdornment>
                        )
                      }}
                      variant="outlined"
                      required={true}
                      error={Boolean(errors?.confirm_password)}
                      helperText={errors?.confirm_password?.message}
                    />
                  )}
                />
              </Box>
            </Box>
            <Box className="flex-basic-center" sx={{ mb: 8 }}>
              <CheckBox
                sx={{ mb: 5 }}
                checked={termsAndConditions}
                onChange={handleTermsAndConditions}
              />
              <Box>
                <Box>
                  {t('set_password.termsConditions')}{' '}
                  <span
                    className="text-medium-semibold text-primary cursorPointer"
                    onClick={() => window.open(TERMS_OF_SERVICE, '_blank')}
                  >
                    {t('set_password.termsOfService')}
                  </span>
                </Box>
              </Box>
            </Box>
            <Box sx={{ my: 2 }} className="flex-column-center">
              {loading ? (
                <CircularProgress sx={{ mb: 2 }} color="primary" />
              ) : (
                <Button
                  sx={{ mb: 1 }}
                  width="button-w-280"
                  btnText={t('set_password.createAccount')}
                  disabled={!isValid || !termsAndConditions}
                  variant="contained"
                  onClick={handleSubmit(onSubmit)}
                />
              )}
              <Button
                btnText={t('set_password.alreadyHaveAccount')}
                variant="text"
                onClick={navigateToLogin}
              />
            </Box>
          </form>
          <Link
            className="flex-basic-center text-medium-semibold text-primary cursorPointer textDecorationNone"
            href={`mailto: ${SUPPORT_EMAILS.HELP_ASSISTANCE}`}
            target="_blank"
            rel="noopener noreferrer"
            sx={{ mt: 8 }}
          >
            {t('set_password.helpAndAssistance')}
          </Link>
        </Box>
      </Box>
    </Box>
  );
};

export default SetPasswordForm;
