import {
  Box,
  IconButton,
  InputAdornment,
  Link,
  useMediaQuery,
  useTheme
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { isMobile } from 'react-device-detect';
import clsx from 'clsx';
import z from 'zod';

import { useRouter } from 'src/providers/RouterProvider';
import { setUserPassword, selectAuthDetail } from './service/auth.service';
import * as ROUTES from '../../shared/constants/routes';
import {
  Button,
  CircularProgress,
  TextField,
  showSuccessMessage
} from '../../shared/components/index';
import { SUPPORT_EMAILS } from 'src/shared/constants/constants';
import { clearStorage, setForgotPasswordToken } from 'src/shared/utils/storage';

const ResetPassword = () => {
  // Constants
  const theme = useTheme();
  const smallDevice = useMediaQuery(theme.breakpoints.down('sm'));
  const { t } = useTranslation('english');
  const { routeTo } = useRouter();
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');
  const dispatch = useDispatch<any>();
  const symbol = /[ `!@#$%^&*()_+\-=\]{};':"\\|,.<>?~]/;
  const number = /\d/;

  // State Variables
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  // Redux Values
  const { setPasswordDetails, loading } = useSelector(selectAuthDetail);

  //Zod Schema
  const SetPasswordFormSchema = z
    .object({
      password: z
        .string()
        .min(1, { message: 'Password is required' })
        .min(9, { message: t('invalidPasswordErrMsg') })
        .max(16, { message: t('invalidPasswordErrMsg') }),
      confirm_password: z
        .string()
        .min(1, { message: 'Re-Type Password is required' })
    })
    .refine((data) => number.test(data.password), {
      message: t('invalidPasswordErrMsg'),
      path: ['password']
    })
    .refine((data) => symbol.test(data.password), {
      message: t('invalidPasswordErrMsg'),
      path: ['password']
    })
    .refine((data) => data.password.toLowerCase() !== data.password, {
      message: t('invalidPasswordErrMsg'),
      path: ['password']
    })
    .refine((data) => data.password.toUpperCase() !== data.password, {
      message: t('invalidPasswordErrMsg'),
      path: ['password']
    })
    .refine((data) => data.password === data.confirm_password, {
      message: 'Confirm password does not match',
      path: ['confirm_password']
    });

  type SetPasswordFormSchemaPayload = z.infer<typeof SetPasswordFormSchema>;

  //Form Variables
  const { control, formState, handleSubmit, setValue } =
    useForm<SetPasswordFormSchemaPayload>({
      resolver: zodResolver(SetPasswordFormSchema),
      mode: 'onChange'
    });
  const { errors, isValid } = formState;

  // Use Effects
  useEffect(() => {
    if (token) {
      setForgotPasswordToken(token);
    }

    return () => {
      clearStorage();
    };
  }, []);

  useEffect(() => {
    if (setPasswordDetails && Object.keys(setPasswordDetails).length) {
      if (setPasswordDetails?.message) {
        showSuccessMessage(setPasswordDetails.message, {
          theme: 'colored',
          position: 'bottom-center',
          autoClose: 3000
        });
        navigateToLogin();
      }
    }
  }, [setPasswordDetails]);

  // Methods
  const onSubmit = (formData: SetPasswordFormSchemaPayload) => {
    const payload = {
      password: formData?.password
    };
    dispatch(setUserPassword(payload));
  };

  const navigateToLogin = () => {
    routeTo(ROUTES.LOGIN);
  };

  return (
    <Box sx={{ px: smallDevice ? 3 : 25, py: 3 }}>
      <Box className="flex-column-center w-100">
        <Box sx={{ mt: 8, mb: 2 }} className="text-h2">
          {t('resetPasswordScreen.title')}
        </Box>
        <Box sx={{ mb: 10, px: 3 }} className="text-medium-regular textalign">
          {t('resetPasswordScreen.subTitle')}
        </Box>
        <Box className="w-100">
          <form>
            <Box className="flex-column-center">
              <Box
                sx={{ mb: 2 }}
                className={clsx({ ['w-50']: !isMobile, ['w-100']: isMobile })}
              >
                <Controller
                  name="password"
                  defaultValue=""
                  control={control}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      fullWidth
                      label={t('set_password.password')}
                      type={showPassword ? 'text' : 'password'}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={() => setShowPassword((prev) => !prev)}
                              edge="end"
                              color={
                                fieldState?.isDirty
                                  ? fieldState?.error
                                    ? 'error'
                                    : 'primary'
                                  : 'default'
                              }
                            >
                              {showPassword ? (
                                <VisibilityIcon />
                              ) : (
                                <VisibilityOffIcon />
                              )}
                            </IconButton>
                          </InputAdornment>
                        )
                      }}
                      variant="outlined"
                      required={true}
                      error={Boolean(errors?.password)}
                      helperText={errors?.password?.message}
                    />
                  )}
                />
              </Box>
              <Box
                sx={{ mb: 5 }}
                className={clsx({ ['w-50']: !isMobile, ['w-100']: isMobile })}
              >
                <Controller
                  name="confirm_password"
                  defaultValue=""
                  control={control}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      fullWidth
                      label={t('set_password.reTypePassword')}
                      type={showConfirmPassword ? 'text' : 'password'}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={() =>
                                setShowConfirmPassword((prev) => !prev)
                              }
                              edge="end"
                              color={
                                fieldState?.isDirty
                                  ? fieldState?.error
                                    ? 'error'
                                    : 'primary'
                                  : 'default'
                              }
                            >
                              {showConfirmPassword ? (
                                <VisibilityIcon />
                              ) : (
                                <VisibilityOffIcon />
                              )}
                            </IconButton>
                          </InputAdornment>
                        )
                      }}
                      variant="outlined"
                      required={true}
                      error={Boolean(errors?.confirm_password)}
                      helperText={errors?.confirm_password?.message}
                    />
                  )}
                />
              </Box>
            </Box>
            <Box sx={{ my: 2 }} className="flex-column-center">
              {loading ? (
                <CircularProgress sx={{ mb: 2 }} color="primary" />
              ) : (
                <Button
                  sx={{ mb: 1 }}
                  width="button-w-280"
                  btnText={t('reset')}
                  disabled={!isValid}
                  variant="contained"
                  onClick={handleSubmit(onSubmit)}
                />
              )}
              <Button
                btnText={t('cancel')}
                width="button-w-280"
                variant="text"
                onClick={navigateToLogin}
              />
            </Box>
          </form>
          <Link
            className="flex-basic-center text-medium-semibold text-primary cursorPointer textDecorationNone"
            href={`mailto: ${SUPPORT_EMAILS.HELP_ASSISTANCE}`}
            target="_blank"
            rel="noopener noreferrer"
            sx={{ mt: 8 }}
          >
            {t('set_password.helpAndAssistance')}
          </Link>
        </Box>
      </Box>
    </Box>
  );
};

export default ResetPassword;
